import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// Styles
import "./ConfigLayout.module.scss";
// Material UI
// Custom Components
import { CircularProgress } from "@mui/material";
import { TabActionsProvider } from "../../contexts/TabActionsContext";
import ConfigContentHeader from "../configContentHeader/ConfigContentHeader";
// Store
import useNavigateToSite from "../../hooks/useNavigateToSite";
import { selectSites, useStoreDispatch } from "../../store";
import { sitesActions } from "../../store/sites";
import BehavioursConfigurator from "../incidentsConfiguration/BehavioursConfigurator";

/**
 * Component responsible for the configuration page layout
 * First component that has the information about the site Id.
 * If no site id is passed then it will be initialized later with the site tree
 * @returns JSX.Element
 */
const ConfigLayoutBehaviors = (): JSX.Element => {
  const { siteRoot } = useSelector(selectSites);
  const dispatch = useStoreDispatch();
  const navigateToSite = useNavigateToSite();

  useEffect(() => {
    dispatch(sitesActions.getAll());
  }, []);

  useEffect(() => {
    if (!siteRoot.data) return;
    navigateToSite(siteRoot.data[0].id);
  }, [siteRoot.data]);

  return (
    <div className="config-layout" data-cr="operator-guide">
      {!siteRoot.data ? (
        <div className="config-layout__spinner" data-cp="player-spinner">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <TabActionsProvider>
          <section className="config-layout__main">
            <div className="config-content">
              <ConfigContentHeader />
              <section className="config-content__panel">
                <BehavioursConfigurator />
              </section>
            </div>
          </section>
        </TabActionsProvider>
      )}
    </div>
  );
};

export default ConfigLayoutBehaviors;
