// General
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useI18n } from "compass-commons";
import { boolean, string, z } from "zod";
// Styles
import "./generalInformation.module.css";
// Store
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircularProgress, IconButton } from "@mui/material";
import { FileCopy, Visibility, VisibilityOff } from "@mui/icons-material";
import { AutoComplete, TextField } from "dms-lib";
import { selectRoot, selectSites, useStoreDispatch } from "../../../../store";
import {
  ConfigEditionModes,
  ConfigModes,
  creationOrEditionMode,
  readOnlyMode,
  rootActions,
} from "../../../../store/root";
import {
  selectSiteBeingManipulated,
  selectSiteCRUDLoading,
  siteBeingManipulated,
  sitesActions,
} from "../../../../store/sites";
// Models
import { FlatSiteTreeNodeDto } from "../../../../models/sites/SiteTreeNodeDto";
// Utils
import { debounce } from "../../../../utils/Util";
import { ConfigurationDTO } from "../../../../models/incidentEscalation/ConfigurationDTO";
import ConfigurationItemTypeDTO from "../../../../models/incidentEscalation/ConfigurationItemTypeDTO";
import ExternalServiceTypeDTO from "../../../../models/incidentEscalation/ExternalServiceTypeDTO";
import IncidentEscalationService from "../../../../services/IncidentEscalationService";

interface GeneralInformationProps {
  timezones: any[];
  cancelAction: any;
  createSite: any;
  updateSite: any;
  updateConfiguration?: any;
  isNogginEnabled?: boolean;
}

/**
 * General Information panel that constains information about the site
 * @returns JSX.Element
 */
const GeneralInformation = ({
  timezones,
  cancelAction,
  createSite,
  updateSite,
  updateConfiguration = () => {},
  isNogginEnabled = false,
}: GeneralInformationProps): JSX.Element => {
  const { t: translate } = useI18n();

  // Redux State Management
  const { configMode, configEditMode } = useSelector(selectRoot);
  const { selectedSiteId, siteRoot, selectedSite } = useSelector(selectSites);
  const isSiteCRUDloading = useSelector(selectSiteCRUDLoading);
  const isSiteBeingManipulated = useSelector(siteBeingManipulated);
  const siteManipulated = useSelector(selectSiteBeingManipulated);
  const isCreationOrEditionMode = useSelector(creationOrEditionMode);
  const isReadOnlyMode = useSelector(readOnlyMode);
  const [nogginApiKey, setNogginApiKey] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [nogginApiKeyMasked, setNogginApiKeyMasked] = useState("");
  const [displayedValue, setDisplayedValue] = useState("");
  const [configuration, setConfiguration] = useState(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(nogginApiKey);
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const dispatch = useStoreDispatch();

  const readOnlyCondition = useMemo(
    () =>
      isSiteCRUDloading || !isCreationOrEditionMode || !isSiteBeingManipulated,
    [isSiteCRUDloading, isCreationOrEditionMode, isSiteBeingManipulated]
  );

  // Local State
  const [siteInfo, setSiteInfo] = useState<FlatSiteTreeNodeDto>(selectedSite);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const updateCurrentSnap = (newSiteInfo: FlatSiteTreeNodeDto) => {
    // Condition important because of concurrency
    // Ex. if our debounce takes longer and the user already clicked the cancel button
    if (!isCreationOrEditionMode) return;

    dispatch(sitesActions.updateCurrentSnap(newSiteInfo));
  };

  const updateWithDebounce = useCallback(
    debounce((newSiteInfo) => updateCurrentSnap(newSiteInfo), 100),
    [isCreationOrEditionMode]
  );

  const updateSiteInfo = (newSiteInfo) => {
    updateWithDebounce(newSiteInfo);
    setSiteInfo(newSiteInfo);
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const changeSiteInfo = (event: any) => {
    const newSiteInfo = {
      ...siteInfo,
      name: event.target.value,
    };

    updateSiteInfo(newSiteInfo);
  };

  //
  // LifeCycles
  //
  useEffect(() => {
    if (!isSiteBeingManipulated) setSiteInfo(selectedSite);
  }, [selectedSite, isSiteBeingManipulated]);

  useEffect(() => {
    if (!isSiteBeingManipulated) return;
    setSiteInfo(siteManipulated);
  }, [siteManipulated, isSiteBeingManipulated]);

  useEffect(() => {
    if (timezones) {
      let defaultOption = {
        name: translate("site.informationPanel.form.timezone.inheritedLabel"),
        id: "-1",
        customLabel: null,
      };

      if (
        siteInfo?.isTimeZoneInherited ||
        siteInfo?.isTimeZoneInherited === null
      ) {
        defaultOption = {
          ...defaultOption,
          customLabel: (
            <>
              <div className="config-general__override-timezone-option-wrapper">
                <div>{defaultOption.name}</div>
                <p>
                  {timezones.find((tz) => tz.id === siteInfo?.timeZone)?.name ||
                    siteInfo?.timeZone}
                </p>
              </div>
            </>
          ),
        };
      }

      setTimeZoneOptions([defaultOption, ...timezones]);
    }
  }, [timezones, siteInfo]);

  // Fetch Configuration
  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        const config = await IncidentEscalationService.getConfiguration();
        setConfiguration(config);
        setNogginApiKey(config?.apiKey || "");
      } catch (error) {
        console.error("Failed to fetch configuration:", error);
      }
    };

    if (
      isNogginEnabled &&
      selectedSiteId &&
      selectedSiteId === siteRoot?.data[0]?.id
    ) {
      fetchConfiguration();
    }
  }, [isNogginEnabled, selectedSiteId, siteRoot]);

  // form configuration

  const getSelectedTimeZone = () => {
    const selectedTimeZone = timeZoneOptions.find(
      (tz) =>
        tz.id ===
        (siteInfo?.isTimeZoneInherited || siteInfo?.isTimeZoneInherited == null
          ? "-1"
          : siteInfo?.timeZone)
    );

    return selectedTimeZone || null;
  };

  const getFormDefaultValue = (site) => {
    return {
      id: site?.id,
      name: site?.name,
      timeZone: getSelectedTimeZone() || {},
      isTimeZoneInherited: site?.isTimeZoneInherited,
      nogginApiKey: configuration?.apiKey || "",
      moduleName:
        configuration?.items?.filter((c) => c.key === "moduleName")[0]?.value ||
        "",
    };
  };

  const siteSchema = z.object({
    id: string().min(1, {
      message: translate("site.informationPanel.form.id.required"),
    }),
    name: string().min(1, {
      message: translate("site.informationPanel.form.name.required"),
    }),
    isTimeZoneInherited: boolean(),
    timeZone: z.object({
      name: string().min(1, {
        message: translate("site.informationPanel.form.timezone.required"),
      }),
      id: string().min(1),
      overrideLabel: z.object({}).optional(),
    }),
    nogginApiKey: string().optional(),
    moduleName: string().optional(),
  });

  type SiteSchemaType = z.infer<typeof siteSchema>;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SiteSchemaType>({
    defaultValues: { ...getFormDefaultValue(siteInfo) },
    resolver: zodResolver(siteSchema),
  });

  useEffect(() => {
    reset({ ...getFormDefaultValue(siteInfo) });
  }, [siteInfo, configuration]);

  const onTimeZoneChanged = (data) => {
    if (data.id) {
      const newSiteInfo = {
        ...siteInfo,
        timeZone: data.id === "-1" ? null : data.id,
        isTimeZoneInherited: data?.id === "-1",
      } as FlatSiteTreeNodeDto;
      updateSiteInfo(newSiteInfo);
    }
  };

  const handleInvalidSave = async (_data) => {
    dispatch(rootActions.activateEditReadOnlyMode());
  };

  const handleSave = () => {
    setLoading(true);
    updateSite();
    if (isNogginEnabled) {
      const payload: ConfigurationDTO = {
        apiKey: nogginApiKey,
        externalServiceType: ExternalServiceTypeDTO.NOGGIN,
        items: [
          {
            key: "moduleName",
            value: moduleName,
            type: ConfigurationItemTypeDTO.STRING,
          },
        ],
      };
      updateConfiguration(payload);
    }
  };

  /**
   * Strategy Config Mode
   */
  const strategyConfigMode = Object.freeze({
    [ConfigModes.CREATE]: async () => {
      createSite();
    },
    [ConfigModes.EDIT]: handleSubmit(handleSave, handleInvalidSave),
  });

  const strategyConfigEditionMode = Object.freeze({
    [ConfigEditionModes.CANCEL]: cancelAction,
    [ConfigEditionModes.SAVE]: () => strategyConfigMode[configMode]?.(),
  });

  // If someone enabledEdit updates, then this hooks is responsable to call the correct
  // edit actions when requested
  useEffect(() => {
    if (!isReadOnlyMode) {
      strategyConfigEditionMode[configEditMode]?.();
    } else {
      setLoading(!isReadOnlyMode);
    }
  }, [configEditMode]);

  const maskNogginApiKey = (apiKey: string): string => {
    return "*".repeat(apiKey.length);
  };

  useEffect(() => {
    setDisplayedValue(
      showPassword ? nogginApiKey : maskNogginApiKey(nogginApiKey)
    );
  }, [nogginApiKey, showPassword]);

  const handleKeyDown = (e) => {
    if (e.ctrlKey || e.metaKey || e.shiftKey) return;
    e.preventDefault();
    const newApiKey = nogginApiKey + e.key;
    setNogginApiKey(newApiKey);
    setDisplayedValue(maskNogginApiKey(newApiKey));
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    const newApiKey = nogginApiKey + pasteData;
    setNogginApiKey(newApiKey);
    setDisplayedValue(maskNogginApiKey(newApiKey));
  };

  return (
    <>
      {loading ? (
        <div
          data-cp="config-general-spinner"
          className="config-general__loading"
        >
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <form>
          <div className="config-general__information">
            <h4 className="config-general__header">
              {translate("site.informationPanel.title")}
            </h4>
            <div className="config-general__content">
              {(siteInfo && (
                <>
                  <div className="config-general__field-wrapper">
                    <span className="config-general__field-label">
                      {translate("site.informationPanel.fields.siteId")}
                    </span>
                    <Controller
                      name="id"
                      control={control}
                      defaultValue={getFormDefaultValue(siteInfo).id}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TextField
                            error={!!errors.id}
                            helperText={errors.id?.message}
                            aria-label={`site-id-${siteInfo.id}`}
                            disabled
                            onChange={onChange}
                            value={value}
                            size="small"
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="config-general__field-wrapper">
                    <span className="config-general__field-label">
                      {translate("site.informationPanel.fields.siteName")}
                    </span>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue={getFormDefaultValue(siteInfo).name}
                      render={({ field: { value } }) => {
                        return (
                          <TextField
                            aria-label={`site-name-${siteInfo.name}`}
                            disabled={readOnlyCondition}
                            onChange={changeSiteInfo}
                            error={!!errors.name}
                            value={value}
                            helperText={errors.name?.message}
                            size="small"
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="config-general__field-wrapper">
                    <span className="config-general__field-label">
                      {translate("site.informationPanel.fields.timezone")}
                    </span>
                    <Controller
                      name="timeZone"
                      control={control}
                      render={() => {
                        return (
                          <AutoComplete
                            className="config-general__site-timezone"
                            key="site-timezone"
                            dataCr="site-timezone"
                            options={timeZoneOptions}
                            getOptionLabel={(option) => option.name}
                            getOptionDisabled={(option) =>
                              option.id === "-1" &&
                              selectedSiteId === siteRoot?.data[0]?.id
                            }
                            id={`config-general__site-timezone-autocomplete-${siteInfo.id}`}
                            disabled={readOnlyCondition}
                            placeholder={translate(
                              "site.informationPanel.form.timezone.placeholder"
                            )}
                            onChangeCallback={(data, _e) => {
                              onTimeZoneChanged(data);
                            }}
                            value={getSelectedTimeZone()}
                            error={!!errors.timeZone}
                            errorMessage={
                              errors.timeZone?.id.message ||
                              errors.timeZone?.name.message
                            }
                          />
                        );
                      }}
                    />
                  </div>
                  {isNogginEnabled && selectedSiteId === siteRoot?.data[0]?.id && (
                    <>
                      <div
                        className="config-general__field-wrapper"
                        style={{ marginTop: "10px", gap: "4px" }}
                      >
                        <span className="config-general__field-label">
                          <div className="api-key-buttons">
                            {translate(
                              "site.informationPanel.fields.nogginApiKey"
                            )}
                            {nogginApiKey && (
                              <div className="config-general__noggin_icon-buttons">
                                <IconButton
                                  onClick={toggleShowPassword}
                                  size="small"
                                >
                                  {showPassword ? (
                                    <Visibility fontSize="inherit" />
                                  ) : (
                                    <VisibilityOff fontSize="inherit" />
                                  )}
                                </IconButton>
                                <IconButton onClick={handleCopy} size="small">
                                  <FileCopy fontSize="inherit" />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        </span>

                        <Controller
                          name="nogginApiKey"
                          control={control}
                          defaultValue={nogginApiKey}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <div className="config-general__api-key-wrapper">
                                {showPassword ? (
                                  <TextField
                                    aria-label={`site-apiKey-${siteInfo?.id}`}
                                    disabled={readOnlyCondition}
                                    onChange={(e) => {
                                      onChange(e);
                                      setNogginApiKey(e.target.value);
                                      setNogginApiKeyMasked(
                                        maskNogginApiKey(e.target.value)
                                      );
                                    }}
                                    onPaste={handlePaste}
                                    value={nogginApiKey}
                                    error={!!errors.nogginApiKey}
                                    helperText={errors.nogginApiKey?.message}
                                    style={{ width: "100%" }}
                                    type="text"
                                    multiline
                                    rows={4}
                                  />
                                ) : (
                                  <TextField
                                    aria-label={`site-apiKeyMasked-${siteInfo?.id}`}
                                    disabled={readOnlyCondition}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => {
                                      onChange(e);
                                      setNogginApiKeyMasked(
                                        maskNogginApiKey(e.target.value)
                                      );
                                    }}
                                    onPaste={handlePaste}
                                    value={displayedValue}
                                    error={!!errors.nogginApiKey}
                                    helperText={errors.nogginApiKey?.message}
                                    style={{ width: "100%" }}
                                    type="text"
                                    multiline
                                    rows={4}
                                  />
                                )}
                              </div>
                            );
                          }}
                        />
                      </div>
                      <div className="config-general__field-wrapper">
                        <span className="config-general__field-label">
                          {translate(
                            "site.informationPanel.fields.nogginModuleName"
                          )}
                        </span>
                        <Controller
                          name="moduleName"
                          control={control}
                          defaultValue={moduleName}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <TextField
                                aria-label={`noggin-module-name-${siteInfo.id}`}
                                disabled={readOnlyCondition}
                                onChange={(e) => {
                                  onChange(e);
                                  setModuleName(e.target.value);
                                }}
                                error={!!errors.moduleName}
                                value={value}
                                helperText={errors.moduleName?.message}
                                size="small"
                              />
                            );
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )) ||
                null}
            </div>
          </div>
        </form>
      )}
    </>
  );
};

GeneralInformation.defaultProps = {
  isNogginEnabled: false,
  updateConfiguration: () => {},
};
export default GeneralInformation;
